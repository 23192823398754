/*--- SLIDER ---*/
.slider-wrapper:not(#premise-slider-wrapper){
  position: relative;
  //height: calc(100vh - 124px);
  height: 100vh;
}

.mobile-layout .slider-wrapper:not(#premise-slider-wrapper){
  height: auto;
  //min-height: 0;
  min-height: calc(100vh - 140px);
}

.slide-content{
  padding: 0 70px;
}

.swiper-container:not(#premise-swiper-container) {
  width: 100%;
  height: 100%;
}

#main-swiper{
  .swiper-slide {
    position: relative;
    text-align: left;
    background: #ffffff;
    font-size: 16px;
    line-height: 1;
    color: #fff;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;

    background-position: center;
    background-size: cover;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: url("../images/slider/s-layer.png") no-repeat top center;
      background-size: cover;
    }

    .title {
      font-size: 3.875em;
      font-weight: 700;
      margin-bottom: 15px;
    }

    .subtitle {
      font-size: 2.5em;
      font-weight: 400;
      color: $blue;
      margin-bottom: 15px;
    }

    p {
      line-height: 1.25;
    }

    .button.help:hover {
      text-decoration: none;
    }
  }
}

.swiper-slide[data-swiper-slide-index="0"]:not(.premise-slide){background-image: url("../images/slider/slide-1.jpg");}
.swiper-slide[data-swiper-slide-index="1"]:not(.premise-slide){background-image: url("../images/slider/slide-2.jpg");}
.swiper-slide[data-swiper-slide-index="2"]:not(.premise-slide){background-image: url("../images/slider/slide-3.jpg");}
.swiper-slide[data-swiper-slide-index="3"]:not(.premise-slide){background-image: url("../images/slider/slide-4.jpg");}
.swiper-slide[data-swiper-slide-index="4"]:not(.premise-slide){background-image: url("../images/slider/slide-5.jpg");}
.swiper-slide[data-swiper-slide-index="5"]:not(.premise-slide){background-image: url("../images/slider/slide-6.jpg");}

.swiper-button-prev,
.swiper-button-next{
  width:60px;
  height: 60px;
  background-size: auto;
}

.swiper-button-prev{
  left: 20px;
  background-image: url("../images/slider/swiper-button-prev.png");
}

.swiper-button-next{
  right: 20px;
  background-image: url("../images/slider/swiper-button-next.png");
}

#premise-swiper-container{
  //padding:15px;
}

#premise-swiper-container .premise-slide img{
  max-width:95%;
  max-height: 260px;
}

#premise-swiper-container .premise-slide .company-name{
  text-align:center;
  max-width:95%;
}

#premise-swiper-container .premise-slide{
  display:flex;
  min-height:280px !important;
  justify-content: space-between;
  align-items:center;
  flex-direction:column;

  .company-name{
    font-weight: bold;
  }

  .name, .email, .phone, .location{
    width:0;
    height:0;
    text-indent:0;
    opacity:0;
  }
}

#premise-swiper-wrapper{
  align-items: center;
  margin: 5px 0 40px 0;
}

#premise-slider-wrapper{
  position: relative;

  .premise-swiper-button-next, .premise-swiper-button-prev{
    position: absolute;
    top:42%;
    width:45px;
    height:45px;
    z-index:999;
    background-size: cover;
    background-position: center center;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    transform: scale(1);
  }

  .premise-swiper-button-next:hover, .premise-swiper-button-prev:hover{
    transform: scale(1.2);
  }

  .premise-swiper-button-prev{
    background-image: url(../images/slider/logos-left.png);
    left:-65px;
  }

  .premise-swiper-button-next{
    background-image: url(../images/slider/logos-right.png);
    right:-65px;
  }
}