/* FONTFACE */
@font-face {
  font-family:'Montserrat';
  src: url('../fonts/Montserrat-Light_gdi.eot');
  src: url('../fonts/Montserrat-Light_gdi.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserrat-Light_gdi.woff') format('woff'),
  url('../fonts/Montserrat-Light_gdi.ttf') format('truetype'),
  url('../fonts/Montserrat-Light_gdi.svg#Montserrat-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-25CA;
}

@font-face {
  font-family:'Montserrat';
  src: url('../fonts/Montserrat-Bold_gdi.eot');
  src: url('../fonts/Montserrat-Bold_gdi.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserrat-Bold_gdi.woff') format('woff'),
  url('../fonts/Montserrat-Bold_gdi.ttf') format('truetype'),
  url('../fonts/Montserrat-Bold_gdi.svg#Montserrat-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-25CA;
}

@font-face {
  font-family:'Montserrat Regular';
  src: url('../fonts/Montserrat-Regular_gdi.eot');
  src: url('../fonts/Montserrat-Regular_gdi.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserrat-Regular_gdi.woff') format('woff'),
  url('../fonts/Montserrat-Regular_gdi.ttf') format('truetype'),
  url('../fonts/Montserrat-Regular_gdi.svg#Montserrat-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-25CA;
}

@font-face {
  font-family:'Montserrat Black';
  src: url('../fonts/Montserrat-Black_gdi.eot');
  src: url('../fonts/Montserrat-Black_gdi.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserrat-Black_gdi.woff') format('woff'),
  url('../fonts/Montserrat-Black_gdi.ttf') format('truetype'),
  url('../fonts/Montserrat-Black_gdi.svg#Montserrat-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-25CA;
}