// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
//@import 'reset';
@import "fonts";
//@import "../vendor/bootstrap/css/bootstrap.min.css";
@import "colors";
@import "input";
@import "slider";


/* FRAME */
html, body{
  background: transparent;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
}

body{
  line-height: 1.5;
  color: $black;

  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  min-height: 100vh;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}


/*.container-fluid > .container{
  padding: 0;
}*/

.flex-container{
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@media (min-width: 768px){
  .flex-container-md{
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

.flex-h-center{
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.flex-v-center{
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.box-padding-lg{
  padding-top: 90px;
  padding-bottom: 90px;
}


.hidden-element{
  display: none;
}

.fadeonscroll{
  opacity: 0;
  @include transition(800ms);
  @include transform(translateY(30px));
}

.fadeonscroll.visible{
  opacity: 1;
  @include transform(translateY(0));
}

/* BASIC STYLES */
.content{
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;

  ul{

    li{

    }
  }
  ul.nomarker{
    padding-left: 0;
    margin-bottom: 15px;

    li{
      list-style: none;
    }
  }
}

/* TYPOGRAPHY */
.content{

    h4{
      font-weight: 800;
      color: $blue;
      font-size: 22px;
    }
}


/* BACK TO TOP */
.cd-top {
  display: inline-block;
  height: 60px;
  width: 60px;
  position: fixed;
  bottom: 40px;
  right: 20px;
  border-radius: 50%;
  @include box-shadow();
  cursor: pointer;
  /* image replacement properties */
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  background: rgba(232, 255, 255, 0.9) url("../images/backtotop.png") no-repeat center 50%;
  visibility: hidden;
  opacity: 0;
  z-index: 10;
  -webkit-transition: opacity .3s 0s, visibility 0s .3s;
  -moz-transition: opacity .3s 0s, visibility 0s .3s;
  transition: opacity .3s 0s, visibility 0s .3s;
}
.cd-top.cd-is-visible, .cd-top.cd-fade-out, .no-touch .cd-top:hover {
  -webkit-transition: opacity .3s 0s, visibility 0s 0s;
  -moz-transition: opacity .3s 0s, visibility 0s 0s;
  transition: opacity .3s 0s, visibility 0s 0s;
}
.cd-top.cd-is-visible {
  /* the button becomes visible */
  visibility: visible;
  opacity: 1;
}
.cd-top.cd-fade-out {
  /* if the user keeps scrolling down, the button is out of focus and becomes less visible */
  opacity: .60;
}

.cd-top:hover{
  opacity: 1;

  -webkit-animation: scrolltop 1.5s infinite;
  animation: scrolltop 1.5s infinite;
}


@-webkit-keyframes scrolltop{
  0% {
    -webkit-transform: translate(0, 0px);
    -ms-transform: translate(0, 0px);
    transform: translate(0, 0px);
  }
  50% {
    -webkit-transform: translate(0, -10px);
    -ms-transform: translate(0, -10px);
    transform: translate(0, -10px);
  }
  100% {
    -webkit-transform: translate(0, 0px);
    -ms-transform: translate(0, 0px);
    transform: translate(0, 0px);
  }
}
@keyframes scrolltop {
  0% {
    -webkit-transform: translate(0, 0px);
    -ms-transform: translate(0, 0px);
    transform: translate(0, 0px);
  }
  50% {
    -webkit-transform: translate(0, -10px);
    -ms-transform: translate(0, -10px);
    transform: translate(0, -10px);
  }
  100% {
    -webkit-transform: translate(0, 0px);
    -ms-transform: translate(0, 0px);
    transform: translate(0, 0px);
  }
}

.no-touch .cd-top:hover {
  background-color: #fff;
  opacity: 1;
}
@media only screen and (min-width: 768px) {
  .cd-top {
    right: 10px;
    bottom: 20px;
  }
}
@media only screen and (min-width: 1024px) {
  .cd-top {
    /*height: 60px;
    width: 60px;
    right: 30px;
    bottom: 30px;*/
  }
}


/* LINKS */
a[href]:not([class]) {
  color: inherit;
  text-decoration: none;
  transition: all ease-in-out .3s;
  cursor: pointer;
}


/* HEADER */
header{
  position: absolute;
  top: 0;
  left:0;
  width: 100%;
  height: 124px;
  z-index: 10;
  border-bottom: 1px solid rgba(255,255,255,0.2);

  .logo{
    content: '';
    display: block;
    width: 140px;
    height: 85px;
    @include background-image(contain);
    background-image: url("../images/header-logo.png");
    text-indent: -9999px;
    cursor: pointer;
    margin-right: 90px;
  }

  .logo.hu{
    background-image: url("../images/header-logo.png");
  }
  .logo.en{
    background-image: url("../images/Automento_logo_en.png");
  }
  .logo.de{
    background-image: url("../images/Automento_logo_de.png");
  }

  .button,
  .button.has-icon{
    padding: 8px 15px !important;
    font-weight: normal !important;
    margin-top: 0 !important;
    font-size: 18px !important;
    line-height: 1.5 !important;
  }

  .nav a{
    position: relative;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    padding: 5px 0;
    margin: 0 20px;
  }

  a:hover{
    text-decoration: none;
  }

  .user-menu a:hover{
    text-decoration: underline !important;
  }

  .nav a.active{
    color:$blue;
  }

  .nav a:after{
    opacity: 0;
    bottom: -8px;
    content: '';
    position: absolute;
    left:50%;
    width: 30px;
    height: 2px;
    background: $blue;

    @include transform(translateX(-50%));
    @include transition(100ms);
  }

  .nav a:hover:after,
  .nav a.active:after{
    bottom: -5px;
    opacity: 1;
    width:50px;
  }

  .user-menu{
    margin-left: auto;

    a{
      color: #fff !important;
      font-size: 18px;
      margin-left: 20px;
    }
  }

  .button-container {
    display: none;
    position: absolute;
    top: 32px;
    right: 20px;
    height: $button-height;
    width: $button-width;
    cursor: pointer;
    z-index: 100;
    transition: opacity .25s ease;

    &:hover {
      opacity: .7;
    }

    &.active {
      position: fixed;

      .top {
        transform: translateY(11px) translateX(0) rotate(45deg);
        background: $color-active;
      }
      .middle {
        opacity: 0;
        background: $color-active;
      }

      .bottom {
        transform: translateY(-11px) translateX(0) rotate(-45deg);
        background: $color-active;
      }
    }

    span {
      background: $color-main;
      border: none;
      height: 5px;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition:  all .35s ease;
      cursor: pointer;

      &:nth-of-type(2) {
        top: 11px;
      }

      &:nth-of-type(3) {
        top: 22px;
      }
    }
  }

  .overlay {
    position: fixed;
    background: $blue;
    top: 0;
    left: 0;
    width: 100%;
    height: 0%;
    opacity: 0;
    visibility: hidden;
    transition: opacity .35s, visibility .35s, height .35s;
    overflow: hidden;

    &.open {
      opacity: .97;
      visibility: visible;
      height: 100%;

      li {
        animation: fadeInRight .5s ease forwards;
        animation-delay: .35s;

        &:nth-of-type(2) {
          animation-delay: .4s;
        }
        &:nth-of-type(3) {
          animation-delay: .45s;
        }
        &:nth-of-type(4) {
          animation-delay: .50s;
        }
      }
    }
    nav {
      position: relative;
      height: 70%;
      top: 50%;
      transform: translateY(-50%);
      font-size: 42px;
      font-weight: 800;
      text-align: center;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0 auto;
      //display: inline-block;
      position: relative;
      height: 100%;

      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;

      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;

      li {
        //height: 25%;
        //height: calc(100% / 4);
        //min-height: 50px;
        position: relative;
        opacity: 0;
        color: #fff;
        line-height: 1;
        padding: 15px;

        a {
          display: block;
          position: relative;
          color: #fff;
          text-decoration: none;
          //overflow: hidden;
          font-size: 28px;

          &:hover:after,
          &:focus:after,
          &:active:after {
            width: 100%;
          }

          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 0%;
            transform: translateX(-50%);
            height: 3px;
            background: $color-link;
            transition: .35s;
          }
        }
      }
    }
  }

  @keyframes fadeInRight {
    0% {
      opacity: 0;
      left: 20%;
    }
    100% {
      opacity: 1;
      left: 0;
    }
  }
}

.mobile-layout header{
  position: relative;
  background-color: #3e3e3e;
}

/* SCROLL DOWN BUTTON */
#scrolldown{
  position: absolute;
  bottom: 70px;
  left: 50%;
  width: 38px;
  height: 70px;
  margin-left: -19px;
  text-indent: -9999px;
  background: url("../images/scrolldown-button.png") no-repeat center;
  background-size: contain;
  -webkit-animation: scrolldown 1.5s infinite;
  animation: scrolldown 1.5s infinite;
  box-sizing: border-box;
  z-index: 1;
}

.mobile-layout #scrolldown{
  background: url("../images/swipe-vertical.png") no-repeat center;
  background-size: contain;
}

@-webkit-keyframes scrolldown{
  0% {
    -webkit-transform: translate(0, 0px);
    -ms-transform: translate(0, 0px);
    transform: translate(0, 0px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 20px);
    -ms-transform: translate(0, 20px);
    transform: translate(0, 20px);
    opacity: 0;
  }
}
@keyframes scrolldown {
  0% {
    -webkit-transform: translate(0, 0px);
    -ms-transform: translate(0, 0px);
    transform: translate(0, 0px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 20px);
    -ms-transform: translate(0, 20px);
    transform: translate(0, 20px);
    opacity: 0;
  }
}

/* SCROLL HORIZONTAL */
.swipe-horizontal{
  position: relative;
  display: none;
  //bottom: 40px;
  //left: 50%;
  width: 60px;
  height: 60px;
  margin: 20px auto 0;
  left:-20px;
  text-indent: -9999px;
  background: url("../images/swipe-horizontal.png") no-repeat center;
  background-size: contain;
  -webkit-animation: swipe-horizontal 1.5s infinite;
  animation: swipe-horizontal 1.5s infinite;
  box-sizing: border-box;
  z-index: 1;
}
.mobile-layout .swipe-horizontal{
  display: block;
}

@-webkit-keyframes swipe-horizontal{
  0% {
    -webkit-transform: translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(-20px, 0);
    opacity: 0;
  }
}
@keyframes swipe-horizontal {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(-20px, 0);
    opacity: 0;
  }
}

/* HOW IT WORKS */
.how-it-works {

  .hiw-icon,
  .icon-arrow{
    width: 180px;
    height: 180px;
    max-width: 100%;
    border-radius: 50%;
    margin: 0 auto;
    @include background-image(contain);
  }

  .hiw-icon.icon-1{
    background-image: url("../images/hiw-icon-1.png")
  }
  .hiw-icon.icon-2{
    background-image: url("../images/hiw-icon-2.png")
  }
  .hiw-icon.icon-3{
    background-image: url("../images/hiw-icon-3.png")
  }

  .hidden-xs{
    padding: 0;
  }

  .icon-arrow{
    width: 82px;
    height: 28px;
    margin-top: 80px;
    background-image: url("../images/hiw-icon-arrow.png")
  }

  h2{
    font-size: 40px;
    font-weight: 800;
    color: $black;
    margin-top: 0;
    margin-bottom: 45px;
  }

  .title{
    font-size: 24px;
    font-weight: bold;
    margin: 30px 0 15px;
  }

  .number{
    color: $blue;
  }
}


/* POSITION */
#position-wrapper{

  h1{
    font-size: 40px;
    font-weight: 800;
  }

  .flex-container > div{
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }

  .box{
    background: #f6f6f6;
    color: $black;
    height: 100%;
    min-height: 420px;
    padding: 20px;
    border-radius: 6px;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 15px;

    > div{
      -webkit-flex: 1 0 0;
      -ms-flex: 1 0 0px;
      flex: 1 0 0;
      display: inline-block;
    }

    .icon{
      height: 250px;
      width: 150px;
      max-width: 150px;
      margin-right: 35px;
      float: none;

      background-color: $blue;
      @include background-image(auto auto);

      text-indent: -9999px;
      border-radius: 6px;

      @include box-shadow();
    }
  }

  .box.gps .icon{
    background-image: url("../images/icon-gps.png");
  }
  .box.address .icon{
    background-image: url("../images/icon-address.png");
  }

  .description{
    font-size: 24px;
    font-weight: 800;
  }
}

.your-position{
  border-top: 1px solid #80b6ff;
  padding-top: 30px;
  padding-bottom: 30px;

  h4, p{
    margin: 0px;
    padding-left: 70px;
    padding-right: 70px;
    color:#fff;
  }

  h4{
    margin-bottom: 10px;
  }

  p span{
    margin: 0 10px;
  }
}

/* CLOSE MAP */
.close-map{
  cursor: pointer;
  padding: 10px 10px;

  #close-map,
  #show-map{
    width: auto;
    display: inline-block;
    padding:10px 10px;
    padding-right: 60px;
  }

  //span:not(.close-button){
  //  margin-left: -35px;
  //}

  .close-button,
  .show-button{
    content: '';
    position: absolute;
    display: inline-block;
    margin-left: 11px;
    margin-top: -9px;
    width: 44px;
    height: 44px;
    background: url("../images/close-map.png") no-repeat center;
    background-size: contain;
  }

  .show-button{
    background: url("../images/show-map.png") no-repeat center;
    background-size: contain;
  }
}

/* MAP */
.map-wrapper{
  position: relative;

}

/* CAR LIST */
.car-list{
  padding-top: 30px;
  padding-bottom: 30px;

  h1{
    font-size: 30px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 45px;
  }

  h2{
    font-size:36px;
  }

  .search-row-container{
    margin-bottom:35px;
  }

  .search-group{
    border-radius: 30px;
    border: 2px solid $blue;
    color: $blue;

    .input-group-addon{
      background-color:transparent;
      border: none;
      color: $blue;
      font-size:20px;
    }

    input{
      font-weight: 400;
    }
  }

  .table{
    border-collapse: separate;
    border-spacing: 0 10px;

    .separator{
      width:10px;
      background: #fff;
      word-wrap: break-word;
      padding: 0;
    }
  }

  .table thead th{
    color: $blue;
    font-size: 22px;
    font-weight: 800;
    border-bottom: none;
    padding: 0 10px 0;
    vertical-align: middle;

    overflow: hidden;
    max-width: 140px;

    &.car-tarif .form-control{
      width: 90px;
    }

    &.car-distance,
    &.car-details{
      max-width: none;
    }

    .form .form-group{
      margin-bottom: 0;
    }
  }

  .table thead th.service-selector{
    max-width:none;
  }

  .table tbody{
    tr td{
      border:none;
      background: #efefef;
      color: #5c5c5c;
      font-size: 18px;
      font-weight: 600;
      padding: 10px 20px;
      vertical-align: middle;

      @include transition(300ms);

      .hidden_tariff{
        display:none !important;
      }

      tr td:nth-last-of-type(3){
        min-width: 162px;
      }
    }

    tr.opened td:not(.separator),
    tr:not(.hidden-element).opened td:last-child,
    tr.hidden-element td{
      background-color: #dedede !important;
      color: #5c5c5c !important;
    }

    tr:not(.hidden-element) td:last-child{
      background-color: $blue;
      color: #ffffff;
      cursor: pointer;

      width:180px;
      white-space: normal !important;
      word-wrap: break-word;
    }

    tr.opened + .hidden-element{
      display: table-row;
    }

    .tow_truck,
    .break_down_service,
    .truck_rescue{
      content: '';
      display: inline-block;
      text-indent: -9999px;
      width:63px;
      height: 30px;

      @include background-image(contain);
      @include crisp-image;
    }

    .tow_truck{background-image: url("../images/icon-automento-lg.png");}
    .break_down_service{background-image: url("../images/icon-esetkocsi-lg.png");}
    .truck_rescue{background-image: url("../images/icon-gepszallitas.png");}

    .premise-row{

      td:first-of-type{
        background-image: url("../images/icon-premise.png");
        background-repeat: no-repeat;
        background-size: 38px;
        background-position: center left 15px;
        padding-left: 66px;
      }
    }

    .driver-row {

      td:first-of-type {
        background-image: url("../images/icon-car.png");
        background-repeat: no-repeat;
        background-size: 28px;
        background-position: center left 15px;
        padding-left: 66px;
      }

      td:not(.separator) {
        background-color: #d9e1ec;
        background-position: center left 15px;
      }
    }
  }

  .form-control{
    background-color: transparent;
    color: $blue;
    font-size: 22px;
    font-weight: 800;
    padding: 0;
    width: 130px;
    border: none;
    cursor: pointer;
    -webkit-box-shadow: 0 0 0 0 transparent;
    -moz-box-shadow: 0 0 0 0 transparent;
    box-shadow: 0 0 0 0 transparent;
  }

  .services
  {
    width: auto;
    white-space: normal;
    max-width: 100%;
    padding-right: 15px !important;
    overflow: hidden !important;
  }
}

#carList.premises-page{
  .contact-buttons{
    a.go-to-profile{
      margin-left: 15px;
      float: none;
    }
  }
}

.contact-buttons{
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  a:not(.button), button{
    content: '';
    display: inline-block;
    width:135px;
    height: 60px;
    margin-right: 15px;
    margin-bottom: 5px;
    color: #fff;
    padding-top: 19px;
    padding-left: 48px !important;
    text-align: left;
    font-size: 16px;
    font-weight: 300;
    border-radius: 6px;

    @include box-shadow();
    @include crisp-image;

    @include background-image(30px auto !important);
    background-position: left 10px center;
  }



  a.phone{
    background-color: $phone-green;
    background-image: url("../images/icon-phone.png");
  }

  a.viber{
    background-color: $viber-purple;
    background-image: url("../images/icon-viber.png");
  }

  a.skype{
    background-color: $skype-blue;
    background-image: url("../images/icon-skype.png");
  }

  .message{
    padding-top: 0;
    border:none;
    background-color: $message-blue;
    background-image: url("../images/icon-message.png");
  }

  a.go-to-profile{
    margin-left: auto;
    float: right;
  }

  a:hover{
    text-decoration: none;
    color: #fff;
  }
}

#service-bar{
  width:100%;

  @include background-image(cover);
  background-image: url("../images/bg-service-bar.jpg");

  h3{
    font-weight: 800;
    font-size: 40px;
    margin-bottom: 30px;
  }

  .offer{
    color:#fff;
    font-size: 24px;
    margin-bottom: 10px;
    padding-left: 60px;

    .icon{
      content: '';
      display: inline-block;
      width: 50px;
      height: 50px;
      margin-right: 10px;
      margin-left: -60px;
      @include background-image(contain);
      @include transform(translateY(18px));
    }

    .icon-1{background-image: url("../images/service-bar-icon-1.png")}
    .icon-2{background-image: url("../images/service-bar-icon-2.png")}
    .icon-3{background-image: url("../images/service-bar-icon-3.png")}
    .icon-4{background-image: url("../images/service-bar-icon-4.png")}
  }
}

#service-bar.germany{
  .offer{
    font-size: 21px;
  }

  .icon{
    width: 40px;
    height: 40px;
  }
}

#contact-bar{
  h3{
    font-weight: 800;
    font-size: 40px;
    margin-bottom: 15px;
  }

  form{
    margin-top: 30px;

    .form-group:nth-child(2){
      margin-top: 30px;
    }
  }
}

/* FOOTER */
footer{

  a,
  a:hover{
    color: #fff !important;
    text-decoration: none;
    cursor: pointer;
  }


  .logo{
    font-size: 48px;
    font-weight: 800;
    line-height: 1;

    h6{
      font-size: 48px;
      font-weight: 800;
      line-height: 1;
    }
  }

  .socialmedia{
    white-space: nowrap;

    span{
      text-indent: -9999px;
      width: 20px;
      height: 20px;
      margin: 5px 1px -4px 0;
      display: inline-block;
      cursor: pointer;
      @include background-image(18px);
    }

    .twitter{background-image: url("../images/social-twitter.png")}
    .facebook{background-image: url("../images/social-facebook.png")}
    .googleplus{background-image: url("../images/social-googleplus.png")}
    .instagram{background-image: url("../images/social-instagram.png")}
  }

  .title{
    font-weight: 600;
    font-size: 21px;
  }

  p{
    margin-top: 15px;
    line-height: 2;
  }

  .address{
    font-size: 13px;
  }

  .col-sm-offset-1 a,
  .links a{
    padding-left: 8px;
    margin-left: -8px;
    border-left:0 solid transparent;
    @include transform(translateX(0));
    @include transition(100ms);
  }

  .col-sm-offset-1 a:hover,
  .links a:hover{
    border-left:3px solid #fff;
    @include transform(translateX(20px));
  }

  .copyright{
    border-top: 1px solid #5b90ff;
    font-size: 13px;
    font-weight: 300;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}


/*--- ABOUT US ---*/
.aboutus-site header{
  background-color: #3e3e3e;
  position: relative;
}

#aboutus{

  img{
    max-width: 100%;
  }

  .text-column-2{
    padding-top: 30px;
  }

  .text-column-2 > h2:first-of-type{
    margin-top: 0;
  }

  ul{
    margin-bottom: 30px;
  }

  ul li{
    line-height: 1.75;
    margin-bottom: 15px;
  }

  h1{
    font-size: 20px;
    color: #006cff;
    font-weight: 700;
  }

  h2{
    text-align: center;
    font-weight: 800;
    font-size: 50px;
    margin-bottom: 30px;
  }

  h3 {
    font-size: 30px;
  }

  h4{
    font-weight: 700;
    font-size: 20px;
  }

  p + h4{
    margin-top: 20px;
  }

  h5{
    font-style: italic;
    font-size: 18px;
    margin-top: 20px;
  }
}


/*--- PREMISE ---*/
.premise-site header{
  background-color: #3e3e3e;
  position: relative;
}

#premise{

  h2{
    font-size: 40px;
    font-weight: 800;
    margin-top: 0;
  }

  h3{
    font-weight: 800;
    color: #006cff;
    font-size: 22px;
  }

  .premise-details{

    h1{
      font-weight: 400;
      font-size: 18px;
      line-height: 1.5;
      margin:0;
      display:inline-block;
    }

    .premise-logo img{
      //max-width: 450px;
      max-width: 100%;
      max-height: 180px;
      height: auto;
      margin-bottom: 30px;
    }

    ul li{
      font-weight: 400;
      font-size: 18px;
      line-height: 1.5;
    }

    .premise-tariff ul li{
      line-height: 1;
      display: inline-block;
      margin: 5px 10px 5px 0;
      padding-right: 10px;
      border-right: 2px solid $blue;
    }

    .premise-tariff ul li:last-child{
      border-right: none;
    }

    .premise-service{
      margin-bottom: 20px;
    }

    .premise-service .service-category{
      padding: 5px 70px 0 0;
      margin-bottom: 10px;
      font-size: 20px;
      font-weight: 800;
      background-repeat: no-repeat;
      @include background-image(56px);
      @include crisp-image;
      background-position: right bottom;
      border-bottom: 1px solid #5c5c5c;
    }

    .premise-service .service-category.break_down_service{
      background-image: url("../images/icon-esetkocsi-lg.png");
    }
    .premise-service .service-category.tow_truck{
      background-image: url("../images/icon-automento-lg.png");
    }

    .premise-service .service-category.truck_rescue{
      background-image: url("../images/icon-gepszallitas-lg.png");
    }

    .premise-service .services{
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 0 10%;
    }

    .premise-service .services span{
      width: 48px;
      height: 48px;
      text-indent: -9999px;
      background-repeat: no-repeat;
      background-size: 42px;
      background-position: center;
      @include transition(100ms);
      cursor: help;
    }

    .premise-service .services span:hover{
      background-size: 48px;
    }

    .premise-service .services span.wheel{background-image: url("../images/service-wheel.png")}
    .premise-service .services span.key{background-image: url("../images/service-key.png")}
    .premise-service .services span.battery{background-image: url("../images/service-battery.png")}
    .premise-service .services span.fuel{background-image: url("../images/service-fuel.png")}
    .premise-service .services span.repair{background-image: url("../images/service-repair.png")}

    .premise-introduction p{
      font-size:16px;
      word-break: break-word;
    }
  }

  .distance{
    padding-top: 35px;
    padding-bottom: 35px;

    h4{
      font-size: 24px;
      font-weight: 800;
      margin-bottom: 20px;
      margin-top: 0;
      color: #fff;
    }

    .distance-value{
      font-weight: 800;
      font-size:48px;
      line-height: 1;
    }
  }

  .machinery{


    .machinery-cars{
      margin-top: 30px;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;

      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;

      .car{
        width: 30%;
        background: #f6f6f6;
        border-radius: 6px;
        border: 1px solid #d8d8d8;
        min-height: 260px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        margin: 15px;

        .image{
          display: block;
          max-width: 340px;
          width:100%;
          height: 200px;
          text-indent: -9999px;
          -webkit-flex: 1 0 auto;
          -ms-flex: 1 0 auto;
          flex: 1 0 auto;
          min-width:100%;
          max-height: 200px;
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;

          @include background-image(cover);
        }

        .description{
          display: block;
          padding: 15px 15px;
          width: auto;
          white-space: normal;
          word-break: break-word;
        }
      }
    }
  }

  .navi-back{
    a.button.has-icon{
      padding:8px 15px;

      span{
        top:3px;
      }
    }
  }
}


/*--- LOGIN ---*/
.login-site{
  background: url("../images/background-1.jpg") no-repeat top center;
  background-attachment: fixed;
  background-size: cover;


  header + .content{
    margin-top: 124px;
  }

  .content{
    //padding: 120px 0;

    #login{
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      max-width: 440px;

      h2{
        margin-top: 0;
        font-size: 40px;
        font-weight: 800;
      }

      h2 + p{
        margin-bottom: 60px;
      }

      .password-link{
        margin-top: 15px;
        font-weight: bold;

        a:hover{color:#fff;}
      }

      .social-login{
        margin-top: 30px;

        .button{
          padding-top: 22px;
          padding-bottom: 22px;
          width: 100%;
        }
      }
    }
  }
}

/*--- REGISTRATION ---*/
.registration-site{
  background: url("../images/background-1.jpg") no-repeat top center;
  background-attachment: fixed;
  background-size: auto;

  header + .content{
    margin-top: 124px;
  }

  .content{

    #registration{
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      max-width: 440px;

      h2{
        margin-top: 0;
        margin-bottom: 40px;
        font-size: 40px;
        font-weight: 800;
      }

      h2 + p{
        margin-bottom: 60px;
      }

      button[type="submit"]{
        margin-bottom: 30px;
      }

      .c-input{
        input[type="checkbox"]{
          width: 40px;
        }

        label a{
          font-weight: 400;
        }

        label a:hover{
          color: #fff;
        }
      }

    }
  }
}

/*--- REGISTRATION FORM ---*/
.registrationform-site{
  //height: auto;


  .button:hover{
    color:#fff;
    text-decoration: underline !important;
  }

  .status-row{
    margin-right:0;
  }

  header{
    background-color: #3e3e3e;
    position: relative;

    .nav.menu{display:none;}
    .logo{cursor:default;}
  }

  .service-type{
    h3{
      margin-bottom: 50px;
    }

    .c-radio > label{
      font-weight: normal;
      font-size: 18px;
    }
  }

  .block-item{
    margin-bottom: 15px;

    .data-block{
      margin: 5px 0;
    }
  }

  //PLAN TYPE
  .plan-type{
    input{
      position: absolute;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity:0;
      cursor: pointer;
    }

    input:checked + .plan{
      background: #fff;
      border: 10px solid $blue;
      @include box-shadow;
    }

    .plan{
      position: relative;
      background-color: #f6f6f6;
      padding: 20px 15px;
      border-radius: 10px;
      border: 10px solid transparent;
      @include transition(150ms);

      .plan-image{
        width:160px;
        height: 160px;
        float: left;
        background-color: $blue;
        background-repeat:no-repeat;
        background-position: center;
        @include background-image(80% auto);
      }

      .plan-info{
        margin-left: 20px;
        font-weight: 800;
        font-size: 24px;

        .name{display: block;}
      }
    }

    & .col-xs-12:nth-of-type(1) .plan-image{background-image: url("../images/payment-1.png")}
    & .col-xs-12:nth-of-type(2) .plan-image{background-image: url("../images/payment-2.png")}
    & .col-xs-12:nth-of-type(3) .plan-image{background-image: url("../images/payment-3.png")}
  }


  //PAYMENT TYPE
  .payment-type{
    .option{
      label{
        padding-top: 80px;
        margin-bottom: 20px;
        width: 110px;
        text-align: center;
        background-repeat: no-repeat;
        @include background-image(auto);
        background-position: top center;

        &.paypal{background-image: url("../images/payment-option-1.png")}
        &.creditcard{background-image: url("../images/payment-option-2.png")}
        &.transfer{background-image: url("../images/payment-option-3.png")}
      }
    }
  }

  .last-row{
    margin: 20px 0;
  }

  #paypal{
    margin: 10px;
  }

  .info-image{
    max-width: 340px;
    height:auto;
    margin: 20px 0;
  }

  .registration-info{
    margin-top:30px;
    margin-bottom: 15px;
  }

  .payment-details{
    margin-top: 30px;
    margin-bottom: 15px;

  }

  .clonedCar{
    margin-bottom: 30px;
  }

  .delete-button{
    margin: 15px 0 15px 15px;
  }

  /*.delete-button.car{
    display: block;
    margin-bottom: 20px;
  }*/


  .drivers{
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    .block-item{
      margin-bottom: 30px;
    }
  }

  .registration-navi{

    & > div{
      //display: inline-block;
    }
  }
}

.stores a{
  width: 130px;
  height: 40px;
  margin: 10px 10px 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  text-indent: -9999px;
  display: inline-block;

  &.googleplay{
    background-image: url("../images/store-googleplay.png");
  }

  &.appstore{
    background-image: url("../images/store-appstore.png");
  }
}

.email-sent-container{
  min-height:500px;

  .email-sent {
    color:white;
    text-align: center;
    margin:75px 0;
  }
}

//BREADCRUMB
.registrationform-site .breadcrumb{
  margin-top: 30px;
  text-transform: uppercase;
  background-color: #ececec;
  //color:#fff;
  border-radius: 12px;
  font-family: "Montserrat Regular";

  .active{
    font-weight: bold;
    color: $color-main;
  }

  li:hover a{
    color: $color-main;
  }
}


@media(max-width:767px){
  .table-responsive{overflow-x:scroll;}
}

.desktop-layout .mobile-visible, .aboutus-site .mobile-visible{
  display: none !important;
}

/* TEXT COLUMNS */
.text-column-2{
  -webkit-columns: 2;
  -moz-columns: 2;
  columns: 2;
  -webkit-column-gap: 30px;
  -moz-column-gap: 30px;
  column-gap: 30px;
}

#aboutus .text-column-2{
  text-align: justify;
}

.column-border-right{
  border-right: 1px solid #dedede;
}

.smallprint{
  font-size: 13px;
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.85);
  /* change if the mask should have another color then white */
  z-index: 9999;
  /* makes sure it stays on top */
  display: none;
}

#status {
  width: 110px;
  height: 110px;
  position: absolute;
  left: 50%;
  top: 50%;
  background-image: url("../images/preloader.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 74px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.pagination{
  display: block;
  text-align: center;

  .previous-container span{
    right:10px;
  }
  .next-container span{
    left:10px;
  }
}

.pagination > div, .pagination .pages, .pagination .next-container, .pagination .previous-container{
  display:inline-block;
  margin: 0 5px 0 5px;
}

.pagination button, .pagination a{
  border:none;
  background:none;
  font-size:16px;
}

.pagination .previous-container, .pagination .next-container{
  color: $color-main;
  margin: 0 20px 0 20px;

  span{
    background-color: $color-main;
    padding: 7px;
    border-radius: 30px;
    color: white;
  }
}

.pagination .pages .active{
  color: $color-main;
  font-weight: bold;
}

.pagination>li>a, .pagination>li>span{
  border:none;
  background-color:transparent;
}

.pagination>li>a{
  padding:1px 6px;
  color: #000000;
}

.pagination>li>a:hover{
  color: $color-main;
}

.pagination>li>a:active{
  background-color: transparent;
}

.pagination>li>a.active, .pagination>li.active>a{
  color: $color-main;
  font-weight: bold;
  background-color:transparent;
}

.pagination>li>a:hover, .pagination>li>span:hover{
  background-color: transparent;
}

.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover{
  background-color:transparent;
}

.pagination .next-container,.pagination .previous-container{
  a{
    color: $color-main;
  }
}

.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover{
  color: $color-main;
}