@import "colors";

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {
  #premise-slider-wrapper{
    padding: 0 50px 0 50px;

    .premise-swiper-button-next, #premise-slider-wrapper .premise-swiper-button-prev{
      width:50px;
      height:50px;
    }

    .premise-swiper-button-prev{
      left:0;
    }

    .premise-swiper-button-next{
      right:0;
    }
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {

  /* HEADER */
  header{
    height: 90px;
    width: 100%;

    .container{
      width:100%;
    }

    .logo{
      width: 102px;
      height: 60px;
      background-position: left center;
      background-size: contain;
    }

    .button-container{
      display: block;
    }
  }

  .nav.menu,
  .user-menu{
    display: none;
  }

  /* CONTACT BUTTONS */
  .contact-buttons{
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .contact-buttons a:not(.button){
    font-size: 14px;
    background-size: 20px;
    margin-right: 10px;
    margin-bottom: 10px;
    padding-left: 30px;
    //width: 110px;
  }

  /*.contact-buttons a.go-to-profile{
    margin-left: 0;
  }*/

  /* DISTANCE */
  .distance{
    .distance-value{
      display: block;
      margin-bottom: 25px;
      margin-top: -5px;
    }
  }

  /* POSITION */
  .position-boxes {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .position-box{
    width: 100% !important;
    float: none;
  }

  #position-wrapper .box{
    min-height:0;
  }

  /* HOW IT WORKS */
  .how-it-works{
    width: 100%;

    .title{
      font-size: 22px;
    }

    .number{
      display: block;
    }
  }

  /* SERVICE BAR */
  #service-bar{
    background-size: cover;
  }

  /* PREMISE */
  #premise{

    /* PREMISE DETAILS */
    .premise-details {
      .premise-info ul li {
        font-size: 14px;
      }
    }

    /* MACHINERY */
    .machinery{
      .machinery-cars{
        margin-top: 30px;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding: 0;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;

        .car{
          margin: 15px 1%;
          width: 46%;

          .image{
          }

          .description{
          }
        }
      }
    }
  }


}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  body{
    display: block;
    //overflow-x: hidden;
  }

  .box-padding-lg{
    padding-top: 45px;
    padding-bottom: 45px;
  }

  /* SLIDER */
  #main-swiper{
    .swiper-slide{
      font-size: 16px;

      .slide-content{
        padding: 0;
      }

      .title{
        font-size:2.4em;
      }

      .subtitle{
        font-size:1.6em;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next{
    width: 34px;
    height: 34px;
    background-size: contain;
    top: auto;
    bottom: 20px;
  }

  .swiper-button-prev{
    left: 33.33%;
    margin-left: -17px;
  }

  .swiper-button-next{
    right: 33.33%;
    margin-right: -17px;
  }

  /* HOW IT WORKS */
  .how-it-works{
    h2{
      margin-bottom: 20px;
    }

    .col-xs-12.col-sm-3{
      margin-bottom: 20px;

      .title{
        margin-top: 10px;
        margin-bottom: 5px;
      }

      .hiw-icon{
        width: 120px;
        height: 120px;
      }
    }
  }

  /* SERVICE BAR */
  #service-bar{
    background-size: auto 100%;

    h3{
      font-size: 24px;
      margin-bottom: 10px;
      margin-top: 0;
    }

    .container > .row > .col-xs-12:first-of-type{
      margin-bottom: 15px;
    }

    .offer{
      margin-bottom: 5px;
      font-size: 18px;
      padding-left: 40px;

      .icon{
        height: 30px;
        width: 30px;
        @include transform(translateY(10px));
        margin-right: 10px;
        margin-left: -40px;
      }
    }
  }

  /* POSITION */
  .your-position{
    padding-left: 0;
    padding-right: 0;

    h4, p {
      padding-left: 15px;
      padding-right: 15px;
    }

    p{
      margin-top: 10px;
    }
  }

  /* CAR LIST */
  .car-list{
    .table-responsive{
      border: none;
    }

    .search-row-container{
      margin-bottom: 0;
    }

    .car-search{
      max-width: 300px;
      margin: 10px auto 30px;

      input{
        width: 100%;
        padding-right: 10px;
      }
    }
  }

  /* CONTACT BUTTONS */
  .contact-buttons a:not(.button){
    font-size: 16px;
    background-size: 32px auto;
    margin-right: 15px;
    padding-left: 40px;
    margin-bottom: 15px;
  }
  .contact-buttons .message{
    margin-bottom: 15px;
  }

  /* PREMISE SERVICES */
  #premise .premise-details{

    .premise-service .services{
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      j-webkit-justify-content: initial;
      -ms-flex-pack: initial;
      justify-content: initial;
      padding: 0;
    }

    .premise-service .services span{
      text-indent: 0;
      width: auto;
      height: 40px;
      line-height: 40px;
      background-position: left center;
      padding-left: 46px;
      margin-bottom: 2px;
      font-weight: 700;
      background-size: 34px;
    }

    .premise-service .services span:hover{
      background-size: 38px;
    }
  }

  /* TEXT COLUMNS */
  .text-column-2{
    -webkit-columns: 1;
    -moz-columns: 1;
    columns: 1;
    -webkit-column-gap: 0;
    -moz-column-gap: 0;
    column-gap: 0;
  }

  /* FOOTER */
  footer{
      text-align: center;

      .logo{
        display: block;
        font-size: 36px;
        margin-bottom: 15px;
      }

    .socialmedia{
      margin: 20px 0;

      a{
        margin-right: 25px;
      }
      a:last-of-type{
        margin-right: 0;
      }
    }

    .links{
      margin-bottom: 0;
    }

  }


  .service-type .c-input{
    margin-bottom: 60px;
  }


  .row.driver-contact{
    margin-top: 50px;
  }


  .not-required.hide{
    display: none;
  }
}


.mobile-layout{
  #main-swiper{
    .swiper-slide{
      font-size: 14px;

      .slide-content{
        padding: 30px 0;
      }

      .title{
        font-size:1.8em;
        margin-top: 30px;
      }

      .button{
        font-size: 16px;
        margin-bottom: 90px;
        padding-left: 60px;

        &:before{
          background-size: 26px;
          width:52px;
        }
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next{
    width: 34px;
    height: 34px;
    background-size: contain;
    top: auto;
    bottom: 50px;
  }

  .swiper-button-prev{
    left: 33.33%;
    margin-left: -17px;
  }

  .swiper-button-next{
    right: 33.33%;
    margin-right: -17px;
  }

  #scrolldown{
    height: 45px;
  }
}

@media only screen and (max-width : 580px) {/* SLIDER */
    /* YOUR POSITION */
  .position-wrapper{
    .swiper-slide{
      font-size: 14px;

      .slide-content{
        padding: 0;
      }

      .title{
        font-size:1.8em;
        margin-top: 30px;
      }

      .button{
        margin-bottom: 90px;
      }
    }

    #scrolldown{
      height: 45px;
    }

    .box{
      f-webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;

      .icon{
        display: block;
        width:100%;
        max-width: none;
        height: 80px;
        line-height: 80px;
        margin-right: 0;
        margin-bottom: 10px;
        background-size: 54px;
      }
    }
  }

  /*.map-wrapper .close-map{
    top: 20px;
  }*/

  /* MACHINERY */
  #premise{
    .machinery{
      .machinery-cars{
        .car{
          width:100%;
          margin: 10px;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
        }
      }
    }
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {

  /* SLIDER */
  #main-swiper{
    .swiper-slide{
      font-size: 12px;

      .title{
        font-size:1.8em;
      }

      .subtitle{
        font-size:1.2em;
      }
    }
  }

  #scrolldown{
    //display: none;
  }

  #position-wrapper .box.gps{
    .icon{
      display:none;
    }
  }

  #position-wrapper .box.address{
    .icon{
      display:none;
    }
  }

  .row.flex-container .how-it-works{
    .hiw-icon{
      width:160px;
      height: 160px;
    }

    .description{
      font-size:1.25em;
    }
  }

  #service-bar .container > .row > .col-xs-12:first-of-type{
    margin-bottom:30px;
  }
}

/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {

}