@import "colors";

/* INPUT FORMS */
.form-control,
.button{
  position: relative;
  height: auto;
  padding: 8px 15px;
  border-radius: 20px;
  border: 2px solid $blue;
  color: #fff;
  background-color: $blue;
  font-size: 18px;
  font-weight: normal;

  @include box-shadow();
  @include transition(300ms);
}

button:hover,
.button:hover{
  text-decoration: underline;
}

button.small,
.button.small{
  font-size: 14px;
}

.button.has-icon{
  position: relative;
  display: inline-block;
  border-radius: 35px;
  border: none;
  padding: 8px 20px 10px 75px;
  font-weight: bold;
  font-size: 1.6em;
  margin-top: 30px;
  line-height: 1.2;
  cursor: pointer;
  color: #fff;

  @include box-shadow();
}

.button.has-icon.cancel-contact{
  background-color: #ff0000;
  padding: 8px 15px;
}

.modal-loader{
  margin: 15px auto
}

.button.has-icon:before{
  content: '';
  position: absolute;
  top:0;
  left:-2px;
  width: 60px;
  height: 100%;
  background-size: 36px;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
}

.button.has-icon.save-button{
  padding: 10px 15px;

  span{
    top:2px;
  }
}

.form-group label a{
  position: relative;
  z-index: 1;
}

.not-required{
  font-style: italic;
  font-size:13px;
  color: #4a4a4a;
}

//HELP
.button.has-icon.help{
  background-color: #ff0000;
}

.button.has-icon.help:before{
  background: #fff url("../images/icon-helpbutton.png") no-repeat center left 15px;
  background-size: 36px;
}

//FACEBOOK
.button.has-icon.facebook{
  background-color: #385a99;
  font-weight: 400;
}

.button.has-icon.facebook:before{
  background: #fff url("../images/icon-facebookbutton.png") no-repeat center left 24px;
  background-size: 20px;
}

//GOOGLE PLUS
.button.has-icon.googleplus{
  background-color: #cd3733;
  font-weight: 400;
}

.button.has-icon.googleplus:before{
  background: #fff url("../images/icon-googleplusbutton.png") no-repeat center left 20px;
  background-size: 32px;
}

.container-fluid .container .clone-row{
  //padding-top: 30px;
  //padding-bottom: 30px;

  .clone-button {
    padding: 15px 17px 10px 12px;
    font-size: 2em;
    margin: 0 10px 0 0;

    span {
      top: inherit;
      bottom: 1px;
      left: 4px;
    }
  }

  label{
    font-weight:normal;
    font-size:16px;
  }
}

.row.driver-contact{
  margin-bottom:60px;
}

/*.row.driver-contact:last-of-type{
  margin-bottom:0;
}*/

/* enable absolute positioning */
.inner-addon {
  position: relative;
}

/* style icon */
.inner-addon .glyphicon {
  position: absolute;
  padding: 10px;
  pointer-events: none;
  z-index:1;
  left: 10px;
  top: 7px;
  width:30px;
  height: 30px;
}

.row .inner-addon .has-icon.phone{
  background: url("../images/reg-icon-phone.png");
  background-size: cover;
}

.row .inner-addon .has-icon.viber{
  background: url("../images/reg-icon-viber.png");
  background-size: cover;
}

.row .inner-addon .has-icon.skype{
  background: url("../images/reg-icon-skype.png");
  background-size: cover;
}

.driver-contact label{
  width: 100%;
  text-align: center;
  font-weight: normal !important;
  font-size: 18px !important;
}

.driver-contact label:first-child{
  width: auto;
  text-align: left;
}

/* add padding  */
.left-addon input  { padding-left:  45px; }

/*.button:hover:after{
  content: '';
  position: absolute;
  top:0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(33,33,33,0.1);
}*/

form.light-input .form-control,
.form-control.light,
.button.light,
.light-input .form-control,
.light-input{
  background-color: #fff;
  color: $blue;
}

#myModal {
  .close{
    font-size:35px;
    color: $blue;
  }

  .modal-header{
    text-align:center;
    color: $blue;
  }

  .close-button-container{
    text-align:left;

    #closeModal{
      background-color: #ff0000;
      padding:8px 15px;
    }
  }

  .modal-body.contact-result-body{
    .text{
      font-weight: bold;
      margin-top: 10px;
    }
    .buttons{
      margin-top:10px;
    }

    .contact-result-text{
      font-weight:bold;
      margin: 10px auto 10px auto;
    }
  }

  .modal-footer{
    padding-top: 25px;
    padding-bottom: 25px;

    button.button{
      margin: 0;
    }
  }

  @media (max-width:450px){
    .modal-footer > div{
      width:100%;
      float: none;
      text-align: center;

      &:first-of-type{
        margin-bottom: 15px;
      }
    }
  }

  .send-button-container{
    text-align: right;

    #sendPhone{
      padding:8px 15px;
    }
  }

  button{
    .glyphicon{
      top:4px;
    }
  }
}

button[type="submit"].form-control{
  text-transform: uppercase;
}

.form-group label{
  font-weight: 800;
  font-size: 24px;
}

.form-group label.checkbox-label{
  font-weight: normal;
  font-size: 16px;
  margin-top: 8px;
}

.inline-labels .form-group label:not(.checkbox-label){
  display: inline-block;
  font-size: 16px;
  font-weight: normal;
  width: 125px;
  float: left;
  margin-top: 8px;
}

.inline-labels .form-group label.full-width{
  width:100%;
}

.inline-labels .form-group label.full-width + input{
  width:100%;
}

.inline-labels.block-labels .form-group label{
  display: block;
  float: none;
  width:100%;
}

.inline-labels .form-group label:not(.checkbox-label) + *{
  display: inline-block;
  width: calc(100% - 125px);
  margin-bottom: 20px;
}

.inline-labels.block-labels .form-group label:not(.checkbox-label) + *{
  display: block;
  width: 100%;
  margin-bottom: 20px;
}

.inline-labels .form-group label:not(.checkbox-label) + .button{
  margin-top: 0;
}

.inline-labels .form-group label:not(.checkbox-label) + .button:hover{
  text-decoration: none;
}

.form .container-fluid > .container{
  padding-left: 15px;
  padding-right: 15px;
}

.registrationform-site .form > div,
.registrationform-site .form-controls{
  padding-top: 30px;
  padding-bottom: 30px;
}

.registrationform-site .form .second-reg-page-status{
  padding-top:0;
}

.form h3{
  font-weight: 800;
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 20px;
}

.form textarea{
  min-height: 176px;
  padding: 15px;
  border-radius: 10px;
  border: 2px solid $blue;
}


.button-file {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.button-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}


::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $blue;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $blue;
}
:-ms-input-placeholder { /* IE 10+ */
  color: $blue;
}
:-moz-placeholder { /* Firefox 18- */
  color: $blue;
}

/* TABLES */
.thead-blue th{
  background-color: $blue;
  color: #fff;
}



/* CUSTOM FORM ELEMENTS */
.c-input {
  position: relative;
  display: inline-block;
  padding-left: 55px;
  cursor: pointer;
  min-height: 40px;
}

.c-input input{
  position: absolute;
  top:0;
  left:0;
  margin-left: 0 !important;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}

.c-indicator {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 40px;
  height: 40px;
  line-height: 20px;
  text-align: center;
  border: 1px solid $blue;
  border-radius: 50%;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  //background-color: #fff;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.c-radio,
.c-checkbox{
  > input{
    margin: 0;
    width:100%;
    height:100%;
  }
  .c-indicator {
    //border-radius: .25rem;
  }

  > label{
    padding-left: 0;
  }
}

.c-radio input~.c-indicator:after,
.c-checkbox input~.c-indicator:after{
  content: '';
  position: absolute;
  top: 2px;
  right: -2px;
  width: 32px;
  height: 26px;
  background: url("../images/c-checkbox-checked.png") no-repeat center;
  background-size: 100%;
  opacity: 0;

  @include transition(150ms);
}
.c-radio input:checked~.c-indicator:after,
.c-checkbox input:checked~.c-indicator:after{
  opacity: 1;
}

.c-input input:checked~.c-indicator {
  color: #fff;
}

.c-radio .c-checkbox{
  display: none;
  margin-top: 15px;
  //margin-left: -30px;
  padding-left: 35px;
  min-height: 0;

  &:first-of-type{
    margin-top: 10px;
  }

  .c-indicator{
    width: 24px;
    height: 24px;
    line-height: 24px;
  }

  input~.c-indicator:after,
  input~.c-indicator:after{
    width: 20px;
    height: 18px;
    right: -3px;
    top: 0;
  }

  label{
    padding-top: 2px;
    font-size: 14px;
  }
}

.c-radio input:checked~.c-checkbox{
  display: block;
}

.c-input{
  .icon{
    margin-top: -38px;
    width: 152px;
    height: 77px;
    text-indent: -9999px;
    @include background-image(contain 100%);
    background-position: left bottom;
    background-repeat: no-repeat;
  }

  input[value="1"]~label .icon{
    background-image: url("../images/icon-automento-lg.png");
  }
  input[value="2"]~label .icon{
    background-image: url("../images/icon-esetkocsi-lg.png");
  }
  input[value="3"]~label .icon{
    background-image: url("../images/icon-gepszallitas.png");
  }
}

/* FORM HELP BLOCK*/
.help-block{
  color: #ff0000;
  font-weight: 700;
  font-size: 14px;

  padding: 10px 10px;
  border-radius: 16px;
  margin: 10px 0 20px;
  /*background: rgba(000,000,000,0.25);*/
}

.registration-navi {
  span{
    top:2px;
  }

  a:hover{
    color: #fff;
  }

  .next-link{
    text-align: right;
  }

  .previous-link{
    text-align: left;
    float: left;
  }
}

.change-password-button{
  width:auto;
  display:inline-block;
  margin:auto auto 40px auto;
}

/* SELECT */
select.form-control {
  -webkit-appearance: none;
  appearance: none;
  padding-right: 20px !important;

  background: url("../images/select-arrow-down.png") no-repeat right center;
  background-size: 15px;
}

/* PAYPAL BUTTON */
#paypal-button{
  max-width: 300px;
  margin: 30px auto 15px;
}