/* COLORS */
$blue: #006cff;
$black: #4a4a4a;
$gray: #3e3e3e;
$phone-green: #4dd637;
$viber-purple: #6e4494;
$skype-blue: #01a2de;
$message-blue: #398bfb;

.background-blue{
  background-color: $blue;
  color: #fff;
}

.background-lightgray{
  background-color: #f6f6f6;
}

.text-blue{color: $blue;}
.text-white{color: #fff;}
.text-gray{color: #909090;}

$color-main: #006cff;
$color-active: #fff;
$color-link: #fff;

$button-height: 27px;
$button-width: 35px;

/*------- MIXINS -------*/
/* SHADOWS */
@mixin box-shadow() {

  -webkit-box-shadow: 0px 2px 3px 0px rgba(74, 74, 74, 0.3);
  -moz-box-shadow: 0px 2px 3px 0px rgba(74, 74, 74, 0.3);
  box-shadow: 0px 2px 3px 0px rgba(74, 74, 74, 0.3);
}
/* BACKGROUND IMAGE*/
@mixin background-image($size){
  background-size: $size;
  background-repeat: no-repeat;
  background-position: center;
}

@mixin transition($time){
  -webkit-transition: all $time ease-in-out !important;
  -moz-transition: all $time ease-in-out !important;
  -ms-transition: all $time ease-in-out !important;
  -o-transition: all $time ease-in-out !important;
  transition: all $time ease-in-out !important;
}

@mixin transform($transform){
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  -o-transform: $transform;
  transform: $transform;
}

@mixin crisp-image{
  image-rendering: -moz-crisp-edges;         /* Firefox */
  image-rendering:   -o-crisp-edges;         /* Opera */
  image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}